import { keyframes } from 'styled-components/macro';

export const flash = keyframes`
  0%{
    opacity: 1;
    transform: scale(1);
  }
  50%{
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const rotate = keyframes`
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
`;

export const ScBlinkDotAnimation = keyframes`
  50% {
    color: transparent;
  }
`;

export const AnimationHideKeyframes = keyframes`
  0%{
    transform: translateX(100%)
  }
  4%{
    transform: translateX(0);
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0
  }
`;

export const AnimationTopHideKeyframes = keyframes`
  0%{
    transform: translateY(-100%)
  }
  4%{
    transform: translateY(0);
    opacity: 1;
  }
  80%{
    opacity: 1;
  }
  100%{
    opacity: 0
  }
`;

export const slowShowAnimation = keyframes`
  0%{opacity:0}
  100%{opacity: 1}
`;

export const ScBlockShowAnimation = keyframes`
  0%{
    clip-path: circle(1% at 0 0);
  }
  100%{
    clip-path: circle(100% at 50% 50%);
  }
`;

export const fadeOut = keyframes`
  0%{
    opacity: 1;
    transform: scale(1);
  }
  100%{
    opacity: 0;
    transform: scale(0);
  }
`;

export const fadeIn = keyframes`
  0%{
    opacity: 0;
    transform: scale(0);
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
`;

export const show = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;

export const widgetAppearanceAnimation = keyframes`
  0% {
    transform: translateX(48px);
}
  100% {
    transform: translateX(-4px);
}
`;

export const widgetDisappearanceAnimation = keyframes`
  0% {
    transform: translateX(-4px);
}
  100% {
    transform: translateX(86px);
}
`;

export const gelatineAnimation = keyframes`
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const combinedAnimation = keyframes`
  0% {
    transform: translateX(-4px); /* Starting point of widgetDisappearanceAnimation */
  }
  40% {
    transform: translateX(86px); /* End point of widgetDisappearanceAnimation */
  }
  40% {
    transform: scale(1, 1); /* Initial state of gelatineAnimation */
  }
  55% {
    transform: scale(0.9, 1.1); /* Gelatine effect starts */
  }
  70% {
    transform: scale(1.1, 0.9);
  }
  85% {
    transform: scale(0.95, 1.05);
  }
  100% {
    transform: scale(1, 1); /* Gelatine effect ends */
  }
`;
