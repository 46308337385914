import i18next, { i18n } from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import * as Sentry from '@sentry/browser';
import { env } from 'helpers/env';

export const fallbackLng = 'en';

export const languagesInfo = (() => {
  const languages =
    require(`../themes/${process.env.THEME}/languages.ts`).default;

  return Object.keys(languages).reduce((acc, key) => {
    acc[key] = languages[key];
    return acc;
  }, {} as { [key: string]: string });
})();

export const availableLng = Object.keys(languagesInfo);

class CustomLanguageDetector extends I18nextBrowserLanguageDetector {
  async = true;
  detect(setLng: any) {
    const detectedLng = super.detect();

    if (detectedLng && detectedLng?.length) {
      setLng(detectedLng);
    }

    return undefined;
  }
}

let i18nInstance: i18n;

const I18nInstanceSetter = {
  type: '3rdParty' as const,
  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };

i18next.on('languageChanged', (lng) => {
  Sentry.addBreadcrumb({
    category: 'localization',
    message: `Language was changed to ${lng}`,
    level: 'info',
  });

  Sentry.setTag('lng', lng);
});

i18next.on('initialized', function () {
  Sentry.addBreadcrumb({
    category: 'localization',
    message: `Localization was initialized`,
    level: 'info',
  });
});

i18next.on('failedLoading', function (lng, ns, msg) {
  Sentry.captureMessage(
    `Failed to load i18n resource ${lng}.${ns}\nMsg: ${msg}`,
    'warning'
  );
});

export default i18next
  .use(CustomLanguageDetector)
  .use(XHR)
  .use(I18nInstanceSetter)
  .use(initReactI18next)
  .init({
    fallbackLng,
    supportedLngs: availableLng,
    backend: {
      loadPath: `${
        process.env.NODE_ENV !== 'development' && process.env.PUBLIC_URL
          ? process.env.PUBLIC_URL
          : ''
      }/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: { v: process.env.BUILD_VERSION },
    },
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'navigator'],
      caches: ['localStorage', 'cookie'],
      lookupLocalStorage: 'lng',
      lookupCookie: 'lng',
      lookupQuerystring: 'lng',
      cookieMinutes: 525600, // 1 year
      cookieDomain: `.${env.REACT_APP_BASE_DOMAIN}`,
    },
    ns: ['common', 'glossary', 'validation'],
    defaultNS: 'common',
    debug: false,
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
