import * as R from 'ramda';
import { Maybe } from 'monet';

const convertDomainToConstant = R.pipe(
  R.split('.'),
  R.map(R.toUpper),
  R.join('_'),
  R.replace(/-/g, '_')
);

const sortEnvironmentPairs = R.sort<[string, string | undefined]>(
  ([keyA], [keyB]) => {
    const hasDoubleUnderscoreA = keyA.includes('__');
    const hasDoubleUnderscoreB = keyB.includes('__');
    if (hasDoubleUnderscoreA && !hasDoubleUnderscoreB) return 1;
    if (!hasDoubleUnderscoreA && hasDoubleUnderscoreB) return -1;
    return 0;
  }
);

const aggregateEnvPairs = (domainConstant: string) =>
  R.reduce((acc, [key, value]) => {
    const newKey = key.includes(`__${domainConstant}__`)
      ? R.replace(`__${domainConstant}__`, '_', key)
      : key;
    acc[newKey] = value;
    return acc;
  }, {} as NodeJS.ProcessEnv);

const transformEnvironment = R.curry(
  (env: NodeJS.ProcessEnv, domainConstant: string) =>
    R.pipe(
      R.toPairs,
      sortEnvironmentPairs,
      aggregateEnvPairs(domainConstant)
    )(env)
);

const getSupportedDomainConstant = (supportedDomains: string[]) =>
  Maybe.fromEmpty(window.location.hostname)
    .filter(R.includes(R.__, supportedDomains))
    .map(convertDomainToConstant);

/**
 * This code dynamically transforms environment variables based on the current domain.
 * It reads the supported domains from the environment variable `REACT_APP_SUPPORTED_DOMAINS`,
 * converts the current domain to a constant-like format, and then transforms the environment variables
 * by replacing parts of their keys if they match the domain constant. This allows for domain-specific
 * environment variables to be used seamlessly.
 *
 * The main steps are:
 * 1. Convert the current domain to a constant-like format.
 * 2. Check if the current domain is in the list of supported domains.
 * 3. If supported, transform the environment variables to replace parts of their keys that match the domain constant.
 * 4. Sort the transformed environment variable pairs to ensure keys with double underscores appear last.
 * 5. Aggregate the transformed pairs back into an object and export it.
 *
 * This process allows for flexible and dynamic configuration based on the domain the application is running on.
 */

export const env = Maybe.fromEmpty(process.env.REACT_APP_SUPPORTED_MIRRORS)
  .map(R.split(','))
  .flatMap(getSupportedDomainConstant)
  .map<NodeJS.ProcessEnv>(transformEnvironment(process.env))
  .orSome(process.env);
