import { Matcher } from 'navi';
import { Router, View } from 'react-navi';
import React, { FC, Suspense, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { Layout } from 'components/Layout';
import { AuthServiceContext } from 'modules/authService/context';
import { useTheme } from '@involve-software/uikit';
import { mouseflowWidget } from 'libs/mouseflow';

function setAuthenticationBreadcrumb(isAuthenticated: boolean) {
  if (isAuthenticated) {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged in',
      level: 'info',
    });
  } else {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'User logged out',
      level: 'info',
    });
  }
}

function setSentryUser(isAuthenticated: boolean) {
  if (!isAuthenticated) {
    Sentry.setUser({ id: 'anonymous' });
  }
}
export const Routes: FC<{ routes: Matcher<any> }> = ({ routes }) => {
  const authService = useContext(AuthServiceContext);

  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    authService.isAuthenticated
  );

  useEffect(() => authService.subscribe(setIsUserAuthenticated), [authService]);
  useEffect(
    () => authService.subscribe(setAuthenticationBreadcrumb),
    [authService]
  );

  useEffect(() => mouseflowWidget.add(), []);

  useEffect(() => authService.subscribe(setSentryUser, true), [authService]);

  const theme = useTheme();

  return (
    <Router
      routes={routes}
      context={{ userAuthenticated: isUserAuthenticated, authService, theme }}
    >
      <Layout>
        <Suspense fallback={null}>
          <View />
        </Suspense>
      </Layout>
    </Router>
  );
};
