import { createGlobalStyle } from 'styled-components/macro';
import { ITheme } from '@involve-software/uikit';

import { device } from 'components/global/MediaQuery';
import { Theme } from 'themes/contracts';

export const GlobalStyles = createGlobalStyle<{ theme: Theme & ITheme }>`
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &.no-scroll{
    overflow: hidden;
  }
}
article{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  background: ${({ theme }) => theme.mainBgImage} ;
}
section{
  @media (${device.desktop}) {
    padding: 0 ${({ theme }) => theme.spacing.primitives['s-12']};
  }
  .margin-top{margin-top: ${({ theme }) => theme.spacing.primitives['s-28']};};
}

body {
  position: relative;
  margin: 0;
  padding-left: calc(100vw - 100%);
  font-family: ${({ theme }) => theme.typography['family']};
  color: ${({ theme }) => theme.colors['text-primary']};
  background: ${({ theme }) => theme.colors['background-01']};
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	@media (${device.tablet}) {
	  padding: 0;
	}
  &.no-scroll{
    overflow: hidden;
  }
}

main {
  display: flex;
  flex: 1;
  max-width: 928px;
  margin: auto;
  width: 100%;
}
main > section{
  box-sizing: border-box;
  flex-grow: 1;
  min-width: 755px;
  @media (${device.tablet}) {
      min-width: 100%;
      padding: 0;
    }
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  margin: ${({ theme }) => theme.spacing.primitives['s-24']} 0;
}
h2,
h3,
p{
  font-family: ${({ theme }) => theme.typography['family']};
}

pre {
  font-family: ${({ theme }) => theme.typography['family']};
  font-size: 1em;
  word-break: break-word;
  white-space: pre-wrap;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: ${({ theme }) => theme.colors['text-link']};
  font-size: inherit;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  &.button-link:hover{
    text-decoration: none;
  }
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
*{
  scrollbar-width: thin;
  scrollbar-color: #c2c2c2 #fbfbfb;
  box-sizing: border-box;
}
button,
input {
  overflow: visible;
  outline: none;
}
input{
  background: transparent;
  &:-webkit-autofill{
    box-shadow: inset 0 100px 0 ${({ theme }) => theme.colors['layer-01']};
    -webkit-text-fill-color: ${({ theme }) => theme.colors['text-primary']};
     caret-color: ${({ theme }) => theme.colors['text-primary']};
  }
}
button,
select {
  text-transform: none;
}
label{
  user-select: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
  background: transparent;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

#nprogress {
 .spinner {
   top: ${({ theme }) => theme.spacing.primitives['s-28']};
 }
 .spinner-icon {
   border-top-color: ${({ theme }) => theme.colors['border-brand']};
   border-left-color: ${({ theme }) => theme.colors['border-brand']};
 }
 .bar {
   background-color: ${({ theme }) => theme.colors['border-brand']};
 }
}
.mobile-hide{
 @media (${device.mobileL}) {
 display: none;
 }
}
.color{
  &--green{
    color: ${({ theme }) => theme.colors['text-success']};
  }
}

.grecaptcha-badge{
  visibility: hidden;
}

`;
