import React, { FC } from 'react';
import * as Sentry from '@sentry/react';
import { Matcher } from 'navi';
import { CssBaseline, ThemeName } from '@involve-software/uikit';

import { Theme } from 'themes/contracts';
import GlobalStyles from './GlobalStyles';
import { InternalErrorBlock } from 'components/internalError';
import { AuthServiceProvider } from 'modules/authService/provider';
import { Routes } from 'router/routes';
import { ThemeProvider } from './themeProvider';

console.log(APP_VERSION);
const themeKey = (process.env.REACT_APP_THEME as ThemeName) || 'kvitum';

const App: FC<{ theme: Theme; routes: Matcher<any> }> = ({ theme, routes }) => {
  return (
    <ThemeProvider theme={theme} themeKey={themeKey}>
      <GlobalStyles theme={theme} />
      <CssBaseline />
      <Sentry.ErrorBoundary
        fallback={
          <section>
            <InternalErrorBlock />
          </section>
        }
      >
        <AuthServiceProvider>
          <Routes routes={routes} />
        </AuthServiceProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
