import { HTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { rotate, ScBlinkDotAnimation } from 'components/global/Animations';
import { hexToRGB } from 'helpers/style';

export interface ScLoaderProps extends HTMLAttributes<HTMLDivElement> {
  inline?: boolean;
  small?: boolean;
  center?: boolean;
  background?: boolean;
}

export const ScLoader = styled.div<ScLoaderProps>`
  //instead of adding !important to override properties
  &&& {
    width: 100%;
  }
  position: ${({ inline }) => (inline ? 'absolute' : 'fixed')};
  text-align: center;
  ${({ center }) =>
    center ? 'top:50%; transform: translateY(-50%);' : 'top: 0;'}
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 102;
  border-radius: ${({ theme }) => theme.radius['radius-cards-strong']};
  ${({ background = true, theme }) =>
    background
      ? `background: ${hexToRGB(theme.colors['layer-01'], 0.8)};`
      : ''};
  img {
    width: ${({ small }) => (small ? 20 : 40)}px;
    animation: ${rotate} 1s linear infinite;
    z-index: 98;
  }
`;

export const ScLoadingText = styled.div`
  color: ${({ theme }) => theme.colors['text-primary']};
  animation: ${ScBlinkDotAnimation} 1s infinite;
`;

export const ScLoaderWrapper = styled.div`
  position: relative;
`;
