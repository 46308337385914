import {
  AxiosResponse,
  AxiosResponseHeaders,
  AxiosResponseTransformer,
} from 'axios';
import { camelize, isObject, toSnake } from 'helpers/common';
import { BlobDownload } from 'api/base';
import { Buffer } from 'buffer';

export interface ServerError {
  data: any;
  errorCode: number;
  message: string;
  result: boolean;
}

export type AxiosServerError = AxiosResponse<ServerError>;

const imagesContentTypes = ['image/jpeg', 'image/svg+xml', 'image/png'];

export enum ContentTypes {
  json = 'application/json',
  csv = 'text/csv; charset=utf-8',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  txt = 'text/plain; charset=utf-8',
}

interface CustomAxiosResponseTransformer {
  (data: any, headers?: AxiosResponseHeaders): any;
}

export function getResponseCamelization(
  blacklists?: string[][]
): CustomAxiosResponseTransformer {
  return function (data, headers) {
    if (
      headers != null &&
      headers['content-type'] != null &&
      headers['content-type'] === ContentTypes.json
    ) {
      let jsonResp;

      try {
        jsonResp = JSON.parse(data);
      } catch (e) {
        return {};
      }

      return camelize(jsonResp, blacklists);
    }

    return data;
  };
}

function parseFilename(headers: { [key: string]: string }) {
  const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;

  const contentDisposition = headers['content-disposition'];

  if (contentDisposition == null) {
    return 'untitled';
  }

  const match = contentDisposition.match(regex);

  if (match == null) {
    return 'untitled';
  }

  return match[1];
}

export const transformFileResponse: AxiosResponseTransformer = (
  data,
  headers
): BlobDownload | { errorCode: number; data: null } | {} => {
  if (headers == null) {
    return {};
  }

  const contentType = headers['content-type'];

  if (contentType == null) {
    return {};
  }

  if (imagesContentTypes.includes(contentType)) {
    return {
      filename: parseFilename(headers),
      blob: data,
    };
  }

  switch (contentType) {
    case ContentTypes.json: {
      const jsonData = getResponseCamelization()(data, headers);

      if (jsonData.errorCode === 0) {
        return {
          errorCode: -1,
          data: null,
        };
      }

      return jsonData;
    }
    case ContentTypes.xlsx:
    case ContentTypes.csv:
    case ContentTypes.txt:
      return {
        filename: parseFilename(headers),
        blob: data,
      };
    default:
      return {};
  }
};

export const transformFileOrJsonResponse: AxiosResponseTransformer = (
  data,
  headers
): BlobDownload | { errorCode: number; data: any } | {} => {
  if (headers == null) {
    return {};
  }

  const contentType = headers['content-type'];
  if (contentType == null) {
    return {};
  }

  switch (contentType) {
    case ContentTypes.json: {
      return getResponseCamelization()(
        Buffer.from(data).toString('utf8'),
        headers
      );
    }
    case ContentTypes.xlsx:
    case ContentTypes.csv:
    case ContentTypes.txt:
      return {
        filename: parseFilename(headers),
        blob: new Blob([data]),
      };
    default:
      return {};
  }
};

export function constructParams(params: (string | object)[]) {
  const readyParams: string[] = [];

  params.forEach((param) => {
    if (isObject(param)) {
      Object.keys(param).forEach((k) => {
        const paramKeys = (param as any)[k] as (string | object)[];

        if (!paramKeys || paramKeys.length === 0) return;

        readyParams.push(k);
        constructParams(paramKeys).forEach((p) => {
          readyParams.push(`${k}.${p}`);
        });
      });
    } else {
      readyParams.push(param as string);
    }
  });

  return readyParams.map((p) => toSnake(p));
}

export function isAxiosServerError(obj: any): obj is AxiosServerError {
  return (
    isObject(obj) &&
    obj.hasOwnProperty('data') &&
    obj.data.hasOwnProperty('errorCode') &&
    obj.data.hasOwnProperty('message')
  );
}

export function parseErrorCode(e: any) {
  return isAxiosServerError(e) ? e.data.errorCode : -1;
}

export function parseErrorMessage(e: any) {
  return isAxiosServerError(e) ? e.data.message : undefined;
}

export function parseErrorData<T = any>(e: any): T {
  return isAxiosServerError(e) ? e.data.data : undefined;
}
