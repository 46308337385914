import { APIParams, factory, PeekAPIParams, TwoFactor } from 'api/base';
import {
  LoginProviders,
  RecaptchaVersion,
  TokenType,
  TwoFactorAction,
} from 'helpers/enums';
import { constructParams } from 'helpers/api';
import { snakify } from 'helpers/common';
import { Balance, BaseAccount, CryptoBalance } from 'api/contracts';

export interface Recaptcha {
  'g-recaptcha-response': string;
}

export interface RecaptchaWithVersion extends Recaptcha {
  recaptchaVersion: RecaptchaVersion;
}

export interface AccountBalance extends Balance {
  hold: number;
  baseAvailable: number | null;
  baseHold: number | null;
  cashback: number | null;
  cashbackFullPayAmount: number | null;
  isCashbackFullPayAmountReached: boolean;
}

export interface AccountCryptoBalance extends CryptoBalance {
  hold: string;
  baseHold: string | null;
  baseAvailable: string | null;
  frozen: string;
  baseFrozen: string | null;
  cashback: string | null;
  cashbackFullPayAmount: string | null;
  isCashbackFullPayAmountReached: boolean;
}

export interface Account extends BaseAccount {
  balances: AccountBalance[];
  cryptoBalances: AccountCryptoBalance[];
  countryId: string | null;
  cashbackUsagePercent: number | null;
  cashbackEarningAllowed: boolean;
  cashbackAvailable: boolean;
}

interface AccountParams extends Account {
  authOperations: any;
  authMethods: any;
}

export interface AuthAddOns {
  encodedBillId?: string;
  encodedCryptoBillId?: string;
  next?: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface ProvidersLoginCredentials {
  code: string;
}

export type LoginCredentialsWithRecaptcha = LoginCredentials &
  RecaptchaWithVersion;

export interface RegisterForm extends RecaptchaWithVersion {
  email: string;
  password: string;
  agreement: boolean;
  lang: string;
}

export interface InitPassRecoveryForm extends RecaptchaWithVersion {
  email: string;
}

export interface PassRecoveryForm {
  password: string;
}

export interface RegistrationConfirmation {
  email: string;
}

export interface RegistrationConfirmationForm {
  password: string;
  lang: string;
}

export interface CodeRequiredForm {
  code: string;
}

export enum MobileAppAuthStatus {
  WAITING = 1,
  SUCCESS,
  REJECTED,
}

interface IMobileAppAuthInfo {
  created: number;
  expired: number;
  ip: string;
  location: string;
  remaining: number;
  status: MobileAppAuthStatus;
  userAgent: string;
}

export const isAuthenticated = factory((r) => () => r<boolean>('get', '/auth'));

export const account = factory(
  (r) =>
    <T extends APIParams<AccountParams>>(params: T[]) =>
      r<PeekAPIParams<Account, T>>('get', '/account', {
        param: constructParams(params),
      })
);

export const deleteAccount = factory(
  (r) => () => r<{ code: number }>('delete', '/account', {})
);

export const register = factory(
  (r) => (form: RegisterForm, addOns?: AuthAddOns) =>
    r<string>(
      'post',
      '/registration/code',
      snakify(addOns ? { ...form, addOns } : form)
    )
);

export const login = factory(
  (r) => (credentials: LoginCredentialsWithRecaptcha, addOns?: AuthAddOns) =>
    r<null>(
      'post',
      '/login',
      snakify(addOns ? { ...credentials, addOns } : credentials)
    )
);

export const loginWith = factory(
  (r) => (service: LoginProviders, addOns?: AuthAddOns) =>
    r<{ authUrl: string }>(
      'post',
      `/login/oauth/${service}`,
      snakify({ addOns })
    )
);

export const getQrToken = factory(
  (r) => () =>
    r<{ token: string; expired: number }>('post', '/login/qr_code/token', {})
);

export const loginWithQr = factory(
  (r) => (token: string) => r<null>('post', '/login/qr_code', { token })
);

export const providersCallback = factory(
  (r) => (service: LoginProviders, queries: string) =>
    r<{ addOns: AuthAddOns }>(
      'get',
      `login/oauth/${service}/callback${queries}`
    )
);

export const twoFactorLogin = factory(
  (r) =>
    (
      credentials: LoginCredentials,
      addOns?: AuthAddOns,
      twoFactor?: TwoFactor
    ) =>
      r<null>(
        'post',
        '/two_factor_login',
        snakify(
          addOns
            ? { ...credentials, ...twoFactor, addOns }
            : { ...credentials, ...twoFactor }
        )
      )
);

export const twoFactorProvidersLogin = factory(
  (r) => (credentials: ProvidersLoginCredentials) =>
    r<{ addOns: AuthAddOns }>('post', '/login/oauth/code', snakify(credentials))
);

export const codeRequiredLogin = factory(
  (r) =>
    (
      credentials: LoginCredentials,
      codeRequired: CodeRequiredForm,
      addOns?: AuthAddOns
    ) =>
      r<null>(
        'post',
        '/login/code',
        snakify(
          addOns
            ? { ...credentials, ...codeRequired, addOns }
            : { ...credentials, ...codeRequired }
        )
      )
);

export const resendCode = factory(
  (r) => (guid: string, recaptcha: RecaptchaWithVersion) =>
    r<null>('post', `/login/code/${guid}/notify`, snakify({ ...recaptcha }))
);

export const logout = factory((r) => () => r<null>('post', '/logout', {}));

export const checkTwoFactor = factory(
  (r) => (action: TwoFactorAction, data: object) =>
    r<boolean>('post', `/auth/operations/${action}/verify`, data)
);

export const verifyToken = factory(
  (r) => (type: TokenType, token: string) =>
    r<{ email: string; attempts?: number }>('get', `/token/${type}/${token}`)
);

export const registrationNotifyCode = factory(
  (r) => (token: string, recaptcha: RecaptchaWithVersion) =>
    r<null>(
      'post',
      `/registration/code/${token}/notify`,
      snakify({ ...recaptcha })
    )
);

export const recoveryNotifyCode = factory(
  (r) => (token: string, recaptcha: RecaptchaWithVersion) =>
    r<null>('post', `/recovery/code/${token}/notify`, snakify({ ...recaptcha }))
);

export const verifyMailCode = factory(
  (r) => (token: string, code: CodeRequiredForm) =>
    r<null>('post', `/registration/code/${token}`, snakify(code))
);

export const initRecovery = factory(
  (r) => (form: InitPassRecoveryForm) =>
    r<string | number>('post', '/recovery/code', snakify(form))
);

export const processRecoveryCode = factory(
  (r) => (token: string, form: PassRecoveryForm) =>
    r<null>('post', `/recovery/code/${token}`, snakify(form))
);

export const getRegistrationConfirmation = factory(
  (r) => (token: string) =>
    r<RegistrationConfirmation>('get', `/registration/confirm/${token}`)
);

export const confirmRegistration = factory(
  (r) => (token: string, form: RegistrationConfirmationForm) =>
    r<null>('post', `/registration/confirm/${token}`, snakify(form))
);

export const mobileAppAuthInfo = factory(
  (r) => (token: string) =>
    r<IMobileAppAuthInfo>('get', `/login/mobile/${token}`)
);

export const mobileAppAuthStatus = factory(
  (r) => (token: string) =>
    r<{ status: MobileAppAuthStatus }>('post', `/login/mobile/${token}`, {})
);

export const mobileAppResendPush = factory(
  (r) => (token: string) =>
    r<{ token: string }>('post', `/login/mobile/${token}/refresh`, {})
);
