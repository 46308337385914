import {
  LoginCredentials,
  login,
  twoFactorLogin,
  logout,
  AuthAddOns,
  LoginCredentialsWithRecaptcha,
  codeRequiredLogin,
  CodeRequiredForm,
  loginWithQr,
} from 'api/personal/auth';
import { TwoFactor } from 'api/base';

import { resetBillsAuthStore } from './store';
import { BaseAuthService } from './service';

export type Subscriber = (isAuthenticated: boolean) => void;

export class PersonalAuthService extends BaseAuthService {
  login = async (
    credentials: LoginCredentialsWithRecaptcha,
    addOns?: AuthAddOns
  ) => {
    const resp = await login.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      addOns
    );

    resetBillsAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  qrLogin = async (token: string) => {
    const resp = await loginWithQr.call(
      { cancelToken: this.cancelSource.token },
      token
    );

    resetBillsAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  twoFactorLogin = async (
    credentials: LoginCredentials,
    twoFactor: TwoFactor,
    addOns?: AuthAddOns
  ) => {
    const resp = await twoFactorLogin.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      addOns,
      twoFactor
    );

    resetBillsAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  codeRequiredLogin = async (
    credentials: LoginCredentials,
    codeRequired: CodeRequiredForm,
    addOns?: AuthAddOns
  ) => {
    const resp = await codeRequiredLogin.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      codeRequired,
      addOns
    );

    resetBillsAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  logout = async () => {
    const resp = await logout.call({ cancelToken: this.cancelSource.token });

    this.setIsAuthenticated(false);

    return resp;
  };
}
