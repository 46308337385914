import { APIParams, factory, PeekAPIParams, TwoFactor } from 'api/base';
import {
  BaseAccount,
  GoogleAuthTwoFactorEnableMethodParams,
} from 'api/contracts';

import {
  LoginProviders,
  RecaptchaVersion,
  TokenType,
  TwoFactorAction,
  TwoFactorType,
} from 'helpers/enums';
import { constructParams } from 'helpers/api';
import { snakify } from 'helpers/common';

export interface Recaptcha {
  'g-recaptcha-response': string;
}

export interface RecaptchaWithVersion extends Recaptcha {
  recaptchaVersion: RecaptchaVersion;
}

export interface Account extends BaseAccount {}

interface AccountParams extends Account {
  authOperations: any;
  authMethods: any;
}

export interface AuthAddOns {
  next?: string;
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface ProvidersLoginCredentials {
  code: string;
}

export type LoginCredentialsWithRecaptcha = LoginCredentials &
  RecaptchaWithVersion;

export interface RegisterForm extends RecaptchaWithVersion {
  email: string;
  password: string;
  agreement: boolean;
  lang: string;
}

export interface InitPassRecoveryForm extends RecaptchaWithVersion {
  email: string;
}

export interface PassRecoveryForm {
  password: string;
}

export interface CodeRequiredForm {
  code: string;
}

export const isAuthenticated = factory(
  (r) => () => r<boolean>('get', '/shops/auth')
);

export const account = factory(
  (r) =>
    <T extends APIParams<AccountParams>>(params: T[]) =>
      r<PeekAPIParams<Account, T>>('get', '/shops/account', {
        param: constructParams(params),
      })
);

export const deleteAccount = factory(
  (r) => () => r<{ code: number }>('delete', '/shops/account', {})
);

export const register = factory(
  (r) => (form: RegisterForm, addOns?: AuthAddOns) =>
    r<string>(
      'post',
      '/shops/registration/code',
      snakify(addOns ? { ...form, addOns } : form)
    )
);

export const login = factory(
  (r) => (credentials: LoginCredentialsWithRecaptcha, addOns?: AuthAddOns) =>
    r<null>(
      'post',
      '/shops/login',
      snakify(addOns ? { ...credentials, addOns } : credentials)
    )
);

export const loginWith = factory(
  (r) => (service: LoginProviders, addOns?: AuthAddOns) =>
    r<{ authUrl: string }>(
      'post',
      `/shops/login/oauth/${service}`,
      snakify({ addOns })
    )
);

export const providersCallback = factory(
  (r) => (service: LoginProviders, queries: string) =>
    r<{ addOns: AuthAddOns }>(
      'get',
      `/shops/login/oauth/${service}/callback${queries}`
    )
);

export const twoFactorLogin = factory(
  (r) =>
    (
      credentials: LoginCredentials,
      addOns?: AuthAddOns,
      twoFactor?: TwoFactor
    ) =>
      r<null>(
        'post',
        '/shops/two_factor_login',
        snakify(
          addOns
            ? { ...credentials, ...twoFactor, addOns }
            : { ...credentials, ...twoFactor }
        )
      )
);

export const twoFactorProvidersLogin = factory(
  (r) => (credentials: ProvidersLoginCredentials) =>
    r<{ addOns: AuthAddOns }>(
      'post',
      '/shops/login/oauth/code',
      snakify(credentials)
    )
);

export const codeRequiredLogin = factory(
  (r) =>
    (
      credentials: LoginCredentials,
      codeRequired: CodeRequiredForm,
      addOns?: AuthAddOns
    ) =>
      r<null>(
        'post',
        '/shops/login/code',
        snakify(
          addOns
            ? { ...credentials, ...codeRequired, addOns }
            : { ...credentials, ...codeRequired }
        )
      )
);

export const resendCode = factory(
  (r) => (guid: string, recaptcha: Recaptcha) =>
    r<null>('post', `/shops/login/code/${guid}/notify`, snakify(recaptcha))
);

export const logout = factory(
  (r) => () => r<null>('post', '/shops/logout', {})
);

export const checkTwoFactor = factory(
  (r) => (action: TwoFactorAction, data: object) =>
    r<boolean>('post', `/shops/auth/operations/${action}/verify`, data)
);

export const verifyToken = factory(
  (r) => (type: TokenType, token: string) =>
    r<{ email: string; attempts?: number }>(
      'get',
      `/shops/token/${type}/${token}`
    )
);

export const registrationNotifyCode = factory(
  (r) => (token: string, recaptcha: Recaptcha) =>
    r<null>(
      'post',
      `/shops/registration/code/${token}/notify`,
      snakify(recaptcha)
    )
);

export const recoveryNotifyCode = factory(
  (r) => (token: string, recaptcha: Recaptcha) =>
    r<null>('post', `/shops/recovery/code/${token}/notify`, snakify(recaptcha))
);

export const verifyMailCode = factory(
  (r) => (token: string, code: CodeRequiredForm, fingerprint?: string) =>
    r<null>('post', `/shops/registration/code/${token}`, snakify(code), {
      headers: {
        ...(fingerprint && { fingerprint }),
      },
    })
);

export const initRecovery = factory(
  (r) => (form: InitPassRecoveryForm) =>
    r<string | number>('post', '/shops/recovery/code', snakify(form))
);

export const processRecoveryCode = factory(
  (r) => (token: string, form: PassRecoveryForm) =>
    r<null>('post', `/shops/recovery/code/${token}`, snakify(form))
);

export interface EditAccountParams {
  defaultLang: string;
  timezone: string;
  ipList: string[];
}

export const editAccount = factory(
  (r) => (params: Partial<EditAccountParams>) =>
    r<null>('put', '/shops/account', snakify(params))
);

export const changePassword = factory(
  (r) => (oldPassword: string, newPassword: string) =>
    r<null>('put', '/shops/password', snakify({ oldPassword, newPassword }))
);

type TwoFactorEditParams = {
  type: TwoFactorType;
  params: TwoFactor;
};

export const enableTwoFactorForAction = factory(
  (r) =>
    (action: TwoFactorAction, { type, params }: TwoFactorEditParams) =>
      r<null>('put', `/shops/auth/operations/${action}/methods/${type}`, params)
);

export const disableTwoFactorForAction = factory(
  (r) => (action: TwoFactorAction, params: TwoFactor) =>
    r<null>('delete', `/shops/auth/operations/${action}`, params)
);

export enum SmsOperations {
  None = 0,

  ChangeAuthOperation = 12,
  RemoveAuthOperation = 13,
  ChangeAuthMethod = 15,
  RemoveAuthMethod = 16,

  CreatePayment = 17,
  ChangeShop = 18,
  ChangeShopInputPaymethodsPayways = 19,
  CreateShopInvoiceRefund = 20,
  CreateShopPayment = 21,
  CreateShopTransfer = 22,
}

export type SendSmsAuthTwoFactorMethodParams = {
  type: TwoFactorType.SMS;
  operation: SmsOperations;
};

export type SMSTwoFactorEnableMethodParams = TwoFactor & {
  type: TwoFactorType.SMS;
};

export const enableGoogleAuthTwoFactorMethod = factory(
  (r) =>
    ({ type, confirmation, data }: GoogleAuthTwoFactorEnableMethodParams) =>
      r<null>('put', `/shops/auth/methods/${type}`, {
        ...confirmation,
        ...data,
      })
);

export const disableGoogleAuthTwoFactorMethod = factory(
  (r) =>
    ({ type, params }: TwoFactorEditParams) =>
      r<null>('delete', `/shops/auth/methods/${type}`, params)
);

export const sendSmsTwoFactorMethod = factory(
  (r) =>
    ({ type, operation }: SendSmsAuthTwoFactorMethodParams) =>
      r<{ token: string }>(
        'post',
        `/shops/auth/methods/${type}/${operation}`,
        {}
      )
);

export const enableSmsTwoFactorMethod = factory(
  (r) =>
    ({ type, ...data }: SMSTwoFactorEnableMethodParams) =>
      r<null>('put', `/shops/auth/methods/${type}`, data)
);
export const disableSmsTwoFactorMethod = factory(
  (r) =>
    ({ type, ...data }: SMSTwoFactorEnableMethodParams) =>
      r<null>('delete', `/shops/auth/methods/${type}`, data)
);

export type ConfirmSmsMethod = { code: string };
